import * as React from "react"
import logo from "../images/logo.png"
import background from "../images/daikin-heatpumps-mock.jpg"
import Helmet from "react-helmet"


function formSubmit(e){
  console.log('submitted');
  if (typeof window !== "undefined") {
    if (window.fbq != null) {
      window.fbq('track', 'Lead');
      console.log('worked');
    }
  }
  // e.preventDefault();
  return true;
}
const IndexPage = () => {
  return (
   <>
   <Helmet>
     <title>Save up to $450 on Daikin mini-split heat pumps | ATL-Refac</title>
   </Helmet>
    <div className="main">
      <div className="container">
        <div className="wrapper">
          <img src={logo} alt="" className="logo" />
          <div className="content">
            <h1>Save up to $450 on Daikin<br/>mini-split&nbsp;heat&nbsp;pumps</h1>
            <p>This is the best time to look at getting a qualifying Daikin single or multi-zone system before the summer arrives.<br/>Offer valid until May 31, 2021.</p>
            <h2>Contact us for a free estimate.</h2>
            <form action="https://getform.io/f/56f7b89c-e681-4a2c-9404-a0bd560da092" onSubmit={formSubmit} method="POST">
            <input type="hidden" id="captchaResponse" name="g-recaptcha-response" />
              <label htmlFor="name">Name<sup>*</sup></label>
              <input id="name" type="text" name="name" required="required" />
              <label htmlFor="email">Email<sup>*</sup></label>
              <input id="email" type="email" name="email" required="required" />
              <label htmlFor="phone">Phone<sup>*</sup></label>
              <input id="phone"type="phone" name="phone" required="required" />
              <label htmlFor="phone">City/Town<sup>*</sup></label>
              <input id="phone"type="text" name="location" required="required"  />
              <div>
                <button type="submit">Send</button>
              </div>

            </form>
          </div>
          
          
          <div className="copyright">
            <span>COPYRIGHT © 2021 ATL-REFAC</span>
          </div>
        </div>
      </div>
      <div className="container"  style={{backgroundImage:'url('+background+')'}}></div>
    </div>
   </> 
  )
}

export default IndexPage
